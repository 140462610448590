import React, { useRef, useEffect } from "react";
import logo from './assets/logoxfera.jpeg';
import descargafirefox from './assets/descargafirefoxcet.svg';
import descargachrome from './assets/descargachromecet.svg';


const ErrorNavegador = (props) => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    var url = "https://videomoneygo.servinformcrm.com/room/" + props.match.params.roomID;

    console.log(props.match.params.roomID);

    if (/android/i.test(userAgent)) {
        return (
            <table class="tableerror">
                <tr>
                    <td>
                        <img class="logoxfera" src={logo} /><br></br>
                    </td>
                </tr>
                <tr>
                    <td class="titleerror">
                        Tu navegador es incompatible o has denegado permisos de cámara y micrófono
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href={url} class="btn btn-success botcolgar">
                        <span class="material-icons">
                            refresh
                        </span>
                        &nbsp; Recargar
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="titlenavegador">
                        Navegadores compatibles
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="https://play.google.com/store/apps/details?id=com.android.chrome&hl=es&gl=US"><img src={descargachrome}></img></a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="https://play.google.com/store/apps/details?id=org.mozilla.firefox&hl=es&gl=US"><img src={descargafirefox}></img></a>
                    </td>
                </tr>
            </table>
        );
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return (
            <table class="tableerror">
                <tr>
                    <td>
                        <img class="logoxfera" src={logo} /><br></br>
                    </td>
                </tr>
                <tr>
                    <td class="titleerror">
                        Tu navegador es incompatible o has denegado permisos de cámara y micrófono
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href={url} class="btn btn-success botcolgar">
                        <span class="material-icons">
                            refresh
                        </span>
                        &nbsp; Recargar
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="titlenavegador">
                        Navegadores compatibles
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="https://apps.apple.com/es/app/google-chrome/id535886823"><img src={descargachrome}></img></a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="https://apps.apple.com/es/app/navegador-firefox/id989804926"><img src={descargafirefox}></img></a>
                    </td>
                </tr>
            </table>
        );
    }

    return (
        <table class="tableerror">
            <tr>
                <td>
                    <img class="logoxfera" src={logo} /><br></br>
                </td>
            </tr>
            <tr>
                <td class="titleerror">
                    Tu navegador es incompatible o has denegado permisos de cámara y micrófono
                </td>
            </tr>
            <tr>
                <td>
                    <a href={url} class="btn btn-success botcolgar">
                    <span class="material-icons">
                        refresh
                    </span>
                    &nbsp; Recargar
                    </a>
                </td>
            </tr>
            <tr>
                <td class="titlenavegador">
                    Navegadores compatibles
                </td>
            </tr>
            <tr>
                <td>
                    <a href="https://www.google.com/intl/es_es/chrome/"><img src={descargachrome}></img></a>
                </td>
            </tr>
            <tr>
                <td>
                    <a href="https://www.mozilla.org/es-ES/firefox/new/"><img src={descargafirefox}></img></a>
                </td>
            </tr>
        </table>
    );
};

export default ErrorNavegador;