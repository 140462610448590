import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CreateRoom from "./routes/CreateRoom";
import ErrorNavegador from "./routes/ErrorNavegador";
import Room from "./routes/Room";
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {/* <Route path="/" exact component={() => { window.location = 'https://www.xferacf.com/index.html'; return null;} }/>
          <Route path="/:aleatorio" exact component={() => { window.location = 'https://www.xferacf.com/index.html'; return null;} }/>
          <Route path="/create/W5bMO5Ia" component={CreateRoom} /> */}
          <Route path="/" exact component={() => { window.location = 'https://www.xferacf.com/index.html'; return null;} } />
          <Route path="/:aleatorio" exact component={CreateRoom} />
          <Route path="/room/:roomID" component={Room} />
          <Route path="/errornavegador/:roomID" component={ErrorNavegador} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
